import React, { useState, useEffect} from "react";
import styles from './Matches.module.scss';
import {Table,Container,Row,Col,Form,Button} from "react-bootstrap"
import ProgressBar from 'react-bootstrap/ProgressBar'
import PlayerPop from '../components/PlayerPop'
import SnackAd from '../components/SnackAd'
import SnackAd2 from '../components/SnackAd2'
import PropTypes from 'prop-types';
import getNormalDistribution from 'get-normal-distribution';

const axios = require("axios");

function GwInfo(props) {

  // const gameWeek = "Gameweek " + props.home[0].gw;
  // const gameWeek = props.home.length>0 ? ("Gameweek " + props.home[0].gw) : null
  // const gameWeek = props.gwPage>0 ? ("Gameweek " + props.gwPage) : null

    if (Object.keys(props.lg).length== 0) {
     return null
   }
   else{

  return(
    <Container fluid className ={styles.gwInfo}>
    <Row>
  <Col style={{marginTop:"-8px",marginBottom:"5px"}}>
  <Button
  variant="dark"
  block
  href={"../"+props.lg.lg_info.slug}>
      Return
      </Button>
  </Col>
</Row>
      <Row>
        <Col>
          <h1 className ={styles.gameweekh1}>
            {props.lg.lg_info.name}
          </h1>
        </Col>
        </Row>
      <Row>
        <Col>
          <h5 className ={styles.updateh5}>
            Page automatically refreshes
          </h5>
        </Col>
      </Row>
    </Container>
  )
}
}

function ChipHdr(props){
  if(props.chip===null){
    return null;
  } else {
    return (
      <div className={styles.h2hh6+' alert alert-success text-center'} style={{marginTop:"-2px",marginBottom:"4px",paddingTop:"1px",paddingBottom:"1px"}}>
      <strong>{props.chip+" Played"}</strong>
      </div>
    )
  }
}

function GameHeader(props) {
  // const src_home = "https://platform-static-files.s3.amazonaws.com/premierleague/badges/t"+ {props.game_details.t1no_code}+".svg";
  if(props.home.length==0){
     return null
   }
   else{

const home_info = props.home[0];
const tots = props.home[2];
// console.log(home_info);
// const home_team = props.home[1];
const away_info = props.away[0];
// const away_tots = props.away[2];

const home_tot= home_info.tot_pts*1-home_info.trans_cost*1;
const away_tot= away_info.tot_pts*1-away_info.trans_cost*1;

const home_live_pred = tots.h_tot_live_proj_sub*1-home_info.trans_cost*1;
const away_live_pred = tots.a_tot_live_proj_sub*1-away_info.trans_cost*1;
const home_live_var = tots.h_tot_live_var_sub*2;
const away_live_var = tots.a_tot_live_var_sub*2;
const covar = 0;
const sqrt_sum = home_live_var+away_live_var-2*covar;
const sqrt = Math.sqrt(sqrt_sum);
const z = (home_live_pred -away_live_pred)/sqrt;
const home_prob = sqrt_sum==0 && home_live_pred==away_live_pred ? 50
: sqrt_sum>0 ? Math.min(Math.max(Math.round(getNormalDistribution(z,0,1)*100),1),99)
:Math.round(getNormalDistribution(z,0,1)*100);
const away_prob = 100-home_prob;
console.log("home_live_var :"+home_live_var );
console.log("away_live_var :"+away_live_var );
console.log("sqrt_sum:"+sqrt_sum);
console.log("sqrt:"+sqrt);
console.log("z:"+z);
console.log("home_prob:"+home_prob);

const home_chip = home_info.chip === "wildcard" ? "Wildcard" : home_info.chip === "freehit" ? "Free Hit" : home_info.chip === "3xc" ? "Triple Captain" : home_info.chip === "bboost" ? "Bench Boost" : home_info.chip === "manager" ? "Assistant Manager" : null;
const away_chip = away_info.chip === "wildcard" ? "Wildcard" : away_info.chip === "freehit" ? "Free Hit" : away_info.chip === "3xc" ? "Triple Captain" : away_info.chip === "bboost" ? "Bench Boost" : away_info.chip === "manager" ? "Assistant Manager" : null;

// const away_team = props.away[1];

  return (
    <Container>
      <Row>
        <Col xs={4} style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <h3 className={styles.homeh2h +" "+styles.h2hh3}> {home_info.manager} </h3>
        </Col>
        <Col xs={4}>
          <h3 className={styles.sch2h+" "+styles.h2hh3}> {home_tot+" - "+away_tot} </h3>
        </Col>
        <Col xs={4} style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <h3 className={styles.awayh2h+" "+styles.h2hh3}> {away_info.manager}</h3>
        </Col>
      </Row>


      <Row style={{marginTop:"-2px"}}>
        <Col xs={4} style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <h6 className={styles.homeh2h +" "+styles.h2hh6 + " font-italic"}> {home_info.team_name} </h6>
        </Col>
        <Col xs={4} style={{marginTop:"-5px"}}>
        </Col>
        <Col xs={4} style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <h6 className={styles.awayh2h +" "+styles.h2hh6 + " font-italic"}> {away_info.team_name}</h6>
        </Col>
      </Row>

      <Row style={{marginTop:"-2px"}}>
        <Col><ChipHdr chip={home_chip}/></Col>
        <Col><ChipHdr chip={away_chip}/></Col>
      </Row>

      <Row  style={{marginTop:"-2px"}}>
      <Col>
        <h6 className={styles.homeh2h +" "+styles.h2hh6 + " font-italic"}> {home_info.trans_cost*-1} </h6>
      </Col>
        <Col>
          <h6 className={styles.h2hh6 + " font-italic"}>
          Transfer Cost
          </h6>
        </Col>
        <Col>
          <h6 className={styles.awayh2h+" "+styles.h2hh6 + " font-italic"}> {away_info.trans_cost*-1} </h6>
        </Col>
      </Row>


      <Row  style={{marginTop:"-2px"}}>
      <Col>
        <h4 className={styles.homeh2h +" "+styles.h2hh4 + " font-italic"}> {"( "+home_live_pred+" )"} </h4>
      </Col>
        <Col>
          <h6 className={styles.h2hh6 + " font-italic"}>
          Projected Scores
          </h6>
        </Col>
        <Col>
          <h4 className={styles.awayh2h +" "+styles.h2hh4 + " font-italic"}> {"( "+away_live_pred+" )"} </h4>
        </Col>
      </Row>

      <Row  style={{marginTop:"-2px"}}>
        <Col>
          <h6 className={styles.h2hh6 + " font-italic"}>
          Win Probability
          </h6>
        </Col>
      </Row>
      <Row style={{marginTop:"-5px",marginBottom:"10px"}}>
        <Col xs={1} className={styles.h2hh6 + " font-italic"} style={{paddingLeft:"1px",paddingRight:"1px"}}> {home_prob + "%"}
        </Col>
        <Col style={{paddingLeft:"5px",paddingRight:"5px"}}>
          <ProgressBar now={home_prob} variant="success" style={{marginRight:"-3px",marginLeft:"-5px",transform:"scaleX(-1)"}}/>
        </Col>
        <Col style={{paddingLeft:"5px",paddingRight:"5px"}}>
        <ProgressBar now={away_prob} variant="success" style={{marginLeft:"-3px",marginRight:"-5px"}}/>
        </Col>
        <Col xs={1} className={styles.h2hh6 + " font-italic"} style={{paddingLeft:"1px",paddingRight:"1px"}}> {away_prob+ "%"}
        </Col>
      </Row>
    </Container>
  );
}
}
// <p className={styles.sc2+ " font-italic"}> {home_prob+"%"} </p>
function PlayersTable(props) {
  // console.log(props.players);
  // return null;

  if(props.players_h.length==0){
    return null
  }
  else{
    // className={
    //   plyr.rc !== 0
    //     ? "table-danger"
    //     : plyr.yc !== 0
    //     ? "table-warning"
    //     : null
    // }
    // className={
    //   plyr.gs_r > 0 || plyr.ass_r > 0
    //     ? "table-success fw-bold"
    //     : null
    // }
  const manager_h_info = props.players_h[0];
  const manager_h_team = props.players_h[1];
  const manager_a_info = props.players_a[0];
  const manager_a_team = props.players_a[1];
  const h_tot_pts_adj = manager_h_info.tot_pts - manager_h_info.trans_cost * 1;
  const a_tot_pts_adj = manager_a_info.tot_pts - manager_a_info.trans_cost * 1;


    const managers = [];
    // const man_h_pls =[];
    var i;
    for(i = 0; i < manager_h_team.length; i++){
      var man_obj ={
        "h_plcode":manager_h_team[i].plcode,
        "h_pl_name":manager_h_team[i].pl_name,
        "h_score":manager_h_team[i].score,
        "h_pord":manager_h_team[i].pord,
        "h_mins":manager_h_team[i].mins,
        "h_pos_name":manager_h_team[i].pos_name,
        "h_live_pts":manager_h_team[i].live_pts,
        "h_wk_pts":manager_h_team[i].wk_pts,
        "h_pred_round":manager_h_team[i].pred_round,
        "h_start":manager_h_team[i].start,
        "h_fin":manager_h_team[i].fin,
        "h_fin_prov":manager_h_team[i].fin_prov,
        "h_no_games":manager_h_team[i].no_games,
        "h_games_played":manager_h_team[i].games_played,
        "h_live_pred":Math.round(manager_h_team[i].live_pred)*Math.max(manager_h_team[i].wgt,1),
        "h_auto_sub_out":manager_h_team[i].auto_sub_out,
        "h_auto_sub_in":manager_h_team[i].auto_sub_in,
        "h_cap_switch":manager_h_team[i].cap_switch,
        "h_bonus_proj":manager_h_team[i].bonus_proj,
        "a_plcode":manager_a_team[i].plcode,
        "a_pl_name":manager_a_team[i].pl_name,
        "a_score":manager_a_team[i].score,
        "a_pord":manager_a_team[i].pord,
        "a_mins":manager_a_team[i].mins,
        "a_pos_name":manager_a_team[i].pos_name,
        "a_live_pts":manager_a_team[i].live_pts,
        "a_wk_pts":manager_a_team[i].wk_pts,
        "a_pred_round":manager_a_team[i].pred_round,
        "a_start":manager_a_team[i].start,
        "a_fin":manager_a_team[i].fin,
        "a_fin_prov":manager_a_team[i].fin_prov,
        "a_no_games":manager_a_team[i].no_games,
        "a_games_played":manager_a_team[i].games_played,
        "a_live_pred":Math.round(manager_a_team[i].live_pred)*Math.max(manager_a_team[i].wgt,1),
        "a_auto_sub_out":manager_a_team[i].auto_sub_out,
        "a_auto_sub_in":manager_a_team[i].auto_sub_in,
        "a_cap_switch":manager_a_team[i].cap_switch,
        "a_bonus_proj":manager_a_team[i].bonus_proj
      }

      managers.push(man_obj);
// Have removed the Object.values because I was trying to make everything flat and pull out the first item. Leaving it in because that was my original direction in the case it was actually the right way to go
    }
  // console.log(man_h);
  // return null;

  return (
    <Table
      size="sm"
      className={styles.players_table + " shadow"}
    >
      <thead className="table-dark">
        <tr>
          <th style={{textAlign:"left"}}>Player</th>
          <th>Pts</th>
          <th>Pts</th>
          <th style={{textAlign:"right"}}>Player</th>
        </tr>
      </thead>
      <tbody>
        {managers.map(plyr => {
          const h_pts = plyr.h_pord <12 ? plyr.h_live_pts : plyr.h_wk_pts;
          const h_pts2 = plyr.h_start==1 || plyr.h_fin==1 ? h_pts : plyr.h_no_games==2 && plyr.h_games_played==2 ? h_pts : "("+plyr.h_live_pred+")";
          const h_pts_format = plyr.h_start==1 || plyr.h_fin==1 ? "" : plyr.h_no_games==2 && plyr.h_games_played==2 ? "" : " font-italic text-secondary";
          const h_pts_format2 = plyr.h_start==1 && plyr.h_fin_prov==0 ? " font-weight-bold" : "";
          const h_dgw = plyr.h_no_games==2 && plyr.h_games_played==1 && plyr.h_fin_prov==1 ? "("+Math.round(plyr.h_live_pred)+")" : plyr.h_no_games==2 && plyr.h_games_played==2 && plyr.h_start==0 ? "("+Math.round(plyr.h_live_pred)+")" :"";
          const a_pts = plyr.a_pord <12 ? plyr.a_live_pts : plyr.a_wk_pts;
          const a_pts2 = plyr.a_start==1 || plyr.a_fin==1 ? a_pts : plyr.a_no_games==2 && plyr.a_games_played==2 ? a_pts : "("+plyr.a_live_pred+")";
          const a_pts_format = plyr.a_start==1 || plyr.a_fin==1 ? "" : plyr.a_no_games==2 && plyr.a_games_played==2 ? "" : " font-italic text-secondary";
          const a_pts_format2 = plyr.a_start==1 && plyr.a_fin_prov==0 ? " font-weight-bold" : "";
          const a_dgw = plyr.a_no_games==2 && plyr.a_games_played==1 && plyr.a_fin_prov==1 ? "("+Math.round(plyr.a_live_pred)+")" : plyr.a_no_games==2 && plyr.a_games_played==2 && plyr.a_start==0 ? "("+Math.round(plyr.a_live_pred)+")" :"";
          // const incBonus = plyr.pord <12 ? plyr.live_pts*1 + plyr.bonus_proj*1 : plyr.wk_pts*1 + plyr.bonus_proj*1
          const rowColour = plyr.h_pord <12 ? "table-default" : "table-secondary";
          // const h_score = plyr.score==="" ? "No game" : plyr.score;
          const h_sub_emoji = plyr.h_auto_sub_out==1 ? "\ud83d\udd03" : plyr.h_auto_sub_in==1 ? "\ud83d\udd03" : plyr.h_cap_switch==1 ? "\u002A\uFE0F\u20E3":"";
          const a_sub_emoji = plyr.a_auto_sub_out==1 ? "\ud83d\udd03" : plyr.a_auto_sub_in==1 ? "\ud83d\udd03" : plyr.a_cap_switch==1 ? "\u002A\uFE0F\u20E3":"";
          const h_mins = plyr.h_no_games==0 ? "" : plyr.h_start==1 || plyr.h_fin==1 ? ", "+ plyr.h_mins + " mins" : "";
          const a_mins = plyr.a_no_games==0 ? "" : plyr.a_start==1 || plyr.a_fin==1 ? ", "+ plyr.a_mins + " mins" : "";
          const h_bonus = plyr.h_bonus_proj > 0 ? "+"+plyr.h_bonus_proj : "";
          const a_bonus = plyr.a_bonus_proj > 0 ? "+"+plyr.a_bonus_proj : "";

          return(
            <tr key={plyr.h_pord} style={{padding:"0"}}  className = {rowColour}>
              <td className={styles.thin_td + h_pts_format2}
                style={{textAlign:"left"}}
              >
                {plyr.h_pl_name}<br/><span className={styles.score_min+" font-italic text-secondary"}>{plyr.h_score+h_mins}</span>
              </td>
              <td className ={"border-right "+styles.thin_td +h_pts_format +h_pts_format2}>
                {h_pts2}<span className="font-italic text-secondary">{h_bonus}</span><br/>{h_sub_emoji}<span className="font-italic text-secondary">{h_dgw}</span>
              </td>
              <td className={styles.thin_td +a_pts_format+a_pts_format2}>
                {a_pts2}<span className="font-italic text-secondary">{a_bonus}</span><br/>{a_sub_emoji}<span className="font-italic text-secondary">{a_dgw}</span>
              </td>
              <td className={styles.thin_td +a_pts_format2}
                style={{textAlign:"right"}}
              >
                {plyr.a_pl_name}<br/><span className={styles.score_min+" font-italic text-secondary"}>{plyr.a_score+a_mins}</span>
              </td>
            </tr>
          )})}
        <tr className = "table-success font-weight-bold">
          <td style={{textAlign:"left"}}>Total</td>
          <td style={{textAlign:"center"}}>{manager_h_info.tot_pts}</td>
          <td style={{textAlign:"center"}}>{manager_a_info.tot_pts}</td>
          <td style={{textAlign:"right"}}>Total</td>
        </tr>
        <tr className = "table-success font-weight-bold">
          <td style={{textAlign:"left"}}>Total (inc transfers)</td>
          <td style={{textAlign:"center"}}>{h_tot_pts_adj}</td>
          <td style={{textAlign:"center"}}>{a_tot_pts_adj}</td>
          <td style={{textAlign:"right"}}>Total (inc transfers)</td>
        </tr>
      </tbody>
    </Table>
  );
}
}


class SelForm extends React.Component {
// function PhaseForm() {
constructor(props) {
    super(props);
    // this.state = {value: '2'};
    this.state = this.props.lg;
    // this.setUrl = this.props.setUrl;
    // this.newUrl = this.props.newUrl;
    this.handleChangeHome = this.handleChangeHome.bind(this);
    this.handleChangeAway = this.handleChangeAway.bind(this);

}

  handleChangeHome(event) {
    this.props.home[0].man_code = event.target.value;
    // this.props.selections.pos = event.target.value;
    this.props.newUrl(this.props.home[0].man_code,this.props.away[0].man_code);
    console.log(event.target.value);
  }

  handleChangeAway(event) {
    this.props.away[0].man_code = event.target.value;
    // this.props.selections.pos = event.target.value;
    this.props.newUrl(this.props.home[0].man_code,this.props.away[0].man_code);
    console.log(event.target.value);
  }

  render() {
    if(this.props.home.length==0 || this.props.lg.entry_list.length==0){
       return null
     }
     else{
       // console.log("this.state");
       // console.log(this.state);
       // console.log(this.props.lg);
    return  (
      <>
      <Row style={{textAlign:"centre",marginLeft: -5,marginRight: -5  }}>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            as="select" value={this.props.home[0].man_code}
            onChange={this.handleChangeHome}
            size="sm"
            >
            {this.props.lg.entry_list.map(plyr => {
              return(
                <option key = {plyr.man_code} value={plyr.man_code}>{plyr.plyr}</option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      <Col style={{ paddingLeft: 5,paddingRight: 5  }}>
      <Form className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            as="select" value={this.props.away[0].man_code}
            onChange={this.handleChangeAway}
            size="sm"
            >
            {this.props.lg.entry_list.map(plyr => {
              return(
                <option key = {plyr.man_code} value={plyr.man_code}>{plyr.plyr}</option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
      </Row>
      </>
    );
}
}
}

          // <td style={{textAlign:"center"}}>{manager_info.tot_pts_bonus}</td>

// 1098641 Forde
// 2647965 Rico
// 10268 Me
// 1719626 bold

// const code1 = 10268;
// const code2 = 1719626;

// const code1 = 1098641;
// const code2 = 2647965;
// const url_base = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/h2h_managers/';
// const url_base = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/h2h_teams?lg_code=';
// const url_lg_start = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/league/1/95788/3/1';

// const live_url = [url_base+code1,url_base+code2];

const useMatchesState = (lgCode,h_man_code,a_man_code) => {
  // const url_lg_start = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/league_list/'+lgCode;
  const url_start = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/h2h_teams?lg_code='+lgCode+'&h_man_code='+h_man_code+'&a_man_code='+a_man_code;
  const [url, setUrl] = useState(url_start)
  // const [responseData_lg, setResponseData_lg] = useState([]);
  const [responseData, setResponseData] = useState([]);

  const newUrl = (h_man_code,a_man_code) => {
      // setUrl(url_base+h_man_code+"/"+a_man_code);
      setUrl(url_start+'&h_man_code='+h_man_code+'&a_man_code='+a_man_code);
  }
  var home = [];
  var away = [];
  var lg={};

  // if (Object.keys(responseData_lg).length > 0) {
  //
  //   lg = responseData_lg;
  //   console.log("responseData_lg-returned");
  //   // console.log(lg);
  //   // console.log(home);
  // }

  if (Object.keys(responseData).length > 0) {

    home = [responseData.h_info,responseData.h_manager_team,responseData.manager_tots]
    away = [responseData.a_info,responseData.a_manager_team]
    lg={'lg_info':responseData.lg_info,'entry_list':responseData.entry_list}
    console.log("responseData-returned");
    // console.log(home);
  }

  // const fetchFpl_lg = async (url) => {
  //   console.log('fetch_url_lg:'+url)
  //   const response = await axios.get(url);
  //   setResponseData_lg(response.data);
  //   if(lgCode==256943){
  //     setUrl([url_base+1098641,url_base+2647965]);
  //   }
  //   else if(lgCode==95788){
  //     setUrl(url_base+10268+"/"+1719626);
  //   }
  //   else{
  //   setUrl(url_base+response.data.league_live[0].man_code+"/"+response.data.league_live[1].man_code);
  // }
  //   console.log("fetchran");
  // };

  const fetchFpl = async (url) => {
    if(url!=null){
    console.log('fetch_url:'+url)
    const response_h = await axios.get(url);
    const response = response_h.data;
    setResponseData(response);
    console.log("fetchran");
  }
  else {
    console.log("empty_url");
  }
};

  // useEffect(() => {
  //   fetchFpl_lg(url_lg_start);
  //   // fetchFpl(url);
  //   console.log("useeffect ran Lg");
  //
  // },[url_lg_start])

  useEffect(() => {
    // fetchFpl_lg(url_lg_start);
    fetchFpl(url);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])


  // },)

return {home,away,lg,newUrl};
}

const HeadtoHead = ({lgCode, h_man_code,a_man_code}) => {

  const {
    home,away,lg,newUrl,
  } = useMatchesState(lgCode,h_man_code,a_man_code);

          // <SelForm lg = {lg} home = {home} away = {away} newUrl = {newUrl}/>
      const btn_link = Object.keys(lg).length== 0? null : lg.lg_info.slug;

  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} xl={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} xl={{span:6, order:2}} className={styles.App2}>
          <GwInfo home={home} lg={lg}/>

          <div style={{ marginBottom: 30 }}>
              <Container fluid className={styles.match_all + " shadow-lg"}>
                <GameHeader home={home} away={away} />
                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col className={styles.home_col}>
                    <PlayersTable
                      players_h={home}
                      players_a={away}
                    />
                  </Col>
                </Row>
                <Row>
                <Col className={styles.index_txt}>
                  <h5> Notes</h5>
                  <ul>
                    <li>Total & Projected Score include transfer costs</li>
                    <li>Projected player scores in ()</li>
                    <li>Projected Score includes &apos;live&apos; bonus points, auto subs & forecasted points for remainder of the game</li>
                    <li>&apos;+&apos; Projected Bonus Points</li>
                    <li>{"\ud83d\udd03" + " Auto Sub out / in"}</li>
                    <li>{"\u002A\uFE0F\u20E3" + " Auto Captain Switch"}</li>
                  </ul>
                  </Col>
                </Row>
              </Container>
          </div>
          <Row stlye={{paddingBottom: "10px"}}>
          <Button variant="dark" size="lg" block href={"../"+btn_link}>
        Return to League
      </Button>
      </Row>

          <Row>
            <Col>
              <SnackAd ad_type = "leader" ad_num = {2}/>
              <br></br>
            </Col>
          </Row>

        </Col>
        <Col xs={{span:12, order:3}} xl={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>

    </Container>
  );
}

export default HeadtoHead;

                // <GameHeader home={home} away={away} />

                // <Row style = {{marginLeft: "0", marginRight:"0"}}>
                //   <Col className={styles.home_col + " order-sm-1"}>
                //     <PlayersTable
                //       players={home}
                //       type="h"
                //     />
                //   </Col>
                //   <Col className={styles.away_col + " order-sm-3"}>
                //     <PlayersTable
                //       players={away}
                //       type="a"
                //     />
                //   </Col>
                // </Row>

// <Row style = {{marginLeft: "0", marginRight:"0"}}>
//   <Col className={styles.home_col + " order-sm-1"}>
//     <PlayersTable
//       players={item.players.h}
//       fid={item.fid}
//       type="h"
//       handleShow = {handleShow}
//     />
//   </Col>
//   <Col className={styles.away_col + " order-sm-3"}>
//     <PlayersTable
//       players={item.players.a}
//       fid={item.fid}
//       type="a"
//       handleShow = {handleShow}
//     />
//   </Col>
// </Row>
